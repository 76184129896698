<template>
	<div :style="{ width, height }">
		<van-image @click.stop="photoPreview" :src="src" :width="width" :height="height" :fit="fit" />
		<van-image-preview v-model="show" :images="images"></van-image-preview>
	</div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
			default: '',
		},
		width: {
			type: [Number, String],
			default: '100%',
		},
		height: {
			type: [Number, String],
			default: '100%',
		},
		fit: {
			type: String,
			default: 'fill'
		},
	},
	data() {
		return {
			show: false,
			images: [],
		}
	},
	methods: {
		photoPreview() {
			this.images = [this.src]
			this.show = true
		},
	},
	beforeDestroy () {
		this.show = false
	}
}
</script>

<style scoped lang="scss"></style>
