<template>
  <div class="upBtn-style">
    <div class="upBtn" @click="scrollToTop">
      <img src="../../../../assets/hw-s111.svg" alt="" style="width: 24px;margin-left: 8px;">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  methods: {
    scrollToTop() {
      const event = document.querySelector('.el-table__body-wrapper') ?? document.querySelector('.content-style')
      if (event) {
        event?.scrollTo({ top: '0', behavior: 'smooth' })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.upBtn-style {
  .upBtn {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(51, 51, 51, 0.3);
    border-radius: 42px;
    position: fixed;
    bottom: 24px;
    right: 8px;
    z-index: 9;
  }
}
</style>
